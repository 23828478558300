// Sidebar navigation

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  .nav-title {
    padding: $sidebar-nav-title-padding-y $sidebar-nav-title-padding-x;
    margin-top: $sidebar-nav-title-margin-top;
    font-size: 80%;
    font-weight: 700;
    color: var(--#{$variable-prefix}sidebar-nav-title-color, $sidebar-nav-title-color);
    text-transform: uppercase;
    @include transition($sidebar-nav-title-transition);
  }

  .nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: $sidebar-nav-link-padding-y $sidebar-nav-link-padding-x;
    color: var(--#{$variable-prefix}sidebar-nav-link-color, $sidebar-nav-link-color);
    text-decoration: none;
    white-space: nowrap;
    background: var(--#{$variable-prefix}sidebar-nav-link-bg, $sidebar-nav-link-bg);
    @include transition($sidebar-nav-link-transition);

    &.active {
      color: var(--#{$variable-prefix}sidebar-nav-link-active-color, $sidebar-nav-link-active-color);
      background: var(--#{$variable-prefix}sidebar-nav-link-active-bg, $sidebar-nav-link-active-bg);

      .nav-icon {
        color: var(--#{$variable-prefix}sidebar-nav-link-active-icon-color, $sidebar-nav-link-active-icon-color);
      }
    }

    &.disabled {
      color: var(--#{$variable-prefix}sidebar-nav-link-disabled-color, $sidebar-nav-link-disabled-color);
      pointer-events: none;
      cursor: not-allowed;
      background: transparent;

      .nav-icon {
        color: var(--#{$variable-prefix}sidebar-nav-link-disabled-icon-color, $sidebar-nav-link-disabled-icon-color);
      }

      &:hover {
        color: var(--#{$variable-prefix}sidebar-nav-link-disabled-color, $sidebar-nav-link-disabled-color);

        .nav-icon {
          color: var(--#{$variable-prefix}sidebar-nav-link-disabled-icon-color, $sidebar-nav-link-disabled-icon-color);
        }

        &.nav-dropdown-toggle::after {
          background-image: var(--#{$variable-prefix}sidebar-nav-group-indicator-hover, escape-svg($sidebar-nav-group-indicator-hover-icon));
        }
      }
    }

    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        color: var(--#{$variable-prefix}sidebar-nav-link-hover-color, $sidebar-nav-link-hover-color);
        text-decoration: none;
        background: var(--#{$variable-prefix}sidebar-nav-link-hover-bg, $sidebar-nav-link-hover-bg);

        .nav-icon {
          color: var(--#{$variable-prefix}sidebar-nav-link-hover-icon-color, $sidebar-nav-link-hover-icon-color);
        }

        &.nav-group-toggle::after {
          background-image: var(--#{$variable-prefix}sidebar-nav-group-indicator-hover, escape-svg($sidebar-nav-group-indicator-hover-icon));
        }
      }
    }
  }

  .nav-icon {
    flex: 0 0 $sidebar-nav-icon-width;
    height: $sidebar-nav-icon-height;
    font-size: $sidebar-nav-icon-font-size;
    color: var(--#{$variable-prefix}sidebar-nav-link-icon-color, $sidebar-nav-link-icon-color);
    text-align: center;
    fill: currentColor;
    @include transition(inherit);

    &:first-child {
      @include ltr-rtl("margin-left", - $sidebar-nav-link-padding-x);
    }
  }

  .nav-group {
    position: relative;
    @include transition($sidebar-nav-group-transition);

    .nav-group-items {
      padding: $sidebar-nav-group-items-padding-y $sidebar-nav-group-items-padding-x;
      overflow: hidden;
      @include transition($sidebar-nav-group-items-transition);
    }

    &:not(.show) .nav-group-items {
      display: none;
    }

    &.show {
      background: var(--#{$variable-prefix}sidebar-nav-group-bg, $sidebar-nav-group-bg);

      .nav-group-toggle {
        color: var(--#{$variable-prefix}sidebar-nav-group-toggle-show-color, $sidebar-nav-group-toggle-show-color);
      }

      > .nav-group-toggle::after {
        transform: rotate(180deg);
      }

      + .show {
        margin-top: 1px;
      }
    }
  }

  .nav-group-toggle {
    cursor: pointer;

    &::after {
      display: block;
      flex: 0 12px;
      height: 12px;
      @include ltr-rtl("margin-left", auto);
      content: "";
      background-image: var(--#{$variable-prefix}sidebar-nav-group-indicator, escape-svg($sidebar-nav-group-indicator-icon));
      background-repeat: no-repeat;
      background-position: center;
      @include transition($sidebar-nav-group-indicator-transition);
    }
  }

  .nav-group-items {
    padding: 0;
    // overflow-y: hidden;
    list-style: none;

    .nav-link {
      @include ltr-rtl("padding-left", $sidebar-nav-icon-width);

      .nav-icon {
        @include ltr-rtl("margin-left", - $sidebar-nav-icon-width);
      }
    }
  }

  &.compact,
  .compact {
    .nav-link {
      padding-top: $sidebar-nav-link-padding-y * .5;
      padding-bottom: $sidebar-nav-link-padding-y * .5;
    }
  }
}
